import { FormPlugin } from '@wix/forms-common'
import _ from 'lodash'
import { FIELDS } from '../../constants/roles'
import { siteStore } from '../stores/site-store'

const addCustomAmountValidation = ({ $w, $form }) => {
  const $customAmountField = $w(`@${FIELDS.ROLE_FIELD_CUSTOM_AMOUNT}`)

  const paymentPluginData = _.find(
    _.get($form, 'connectionConfig.plugins'),
    (plugin) => plugin.id === FormPlugin.PAYMENT_FORM,
  )

  _.forEach($customAmountField, ($field: WixCodeField) => {
    const productId = _.get($field, 'connectionConfig.productId')
    const productData = _.get(paymentPluginData, `payload.items[${productId}]`)

    const defaultPriceValue = _.toNumber(_.get(productData, 'price'))

    if (defaultPriceValue) {
      $field.value = defaultPriceValue.toString()
    }

    $field.onCustomValidation((value, reject) => {
      const number = _.toNumber(value)

      if (_.isNaN(number)) {
        reject(siteStore.t('paymentField.invalidFormat'))
      } else {
        const minValue = _.toNumber(_.get(productData, 'min', 0))
        const maxValue = _.toNumber(_.get(productData, 'max', Number.MAX_SAFE_INTEGER))

        if (number < minValue || number > maxValue) {
          reject(siteStore.t('paymentField.outOfRange'))
        }
      }
    })
  })
}

export const registerPaymentFieldsIfExists = (payload) => {
  addCustomAmountValidation(payload)
}
